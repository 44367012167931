import {createRouter, createWebHistory} from "vue-router";
// const cookies = require("js-cookie");

const routes = [

    {
        path: "/redirect",
        component: () => import(/* webpackChunkName: "main" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Redirect"),
            },
        ],
    },
    {
        path: "/login",
        component: () => import(/* webpackChunkName: "register" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Login"),
            },
        ],
    },
    {
        path: "/register",
        component: () => import(/* webpackChunkName: "main" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Register.vue"),
            },
        ],
    },
    {
        path: "/signup",
        component: () => import(/* webpackChunkName: "signup" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Signup.vue"),
            },
        ],
    },
    {
        path: "/password-forgot",
        component: () => import(/* webpackChunkName: "main" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Passwordforgot"),
            },
        ],
    },

    {
        path: "/password-reset",
        component: () => import(/* webpackChunkName: "main" */ "../views/auth/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/auth/Passwordreset"),
            },
        ],
    },

    {
        path: "/calender",

        component: () => import(/* webpackChunkName: "main" */ "../views/calender/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/calender/Calender"),
            },
        ],
    },

    {
        path: "/account-setup",
        component: () => import(/* webpackChunkName: "main" */ "../views/account/__template_setup.vue"),
        children: [
            {
                path: "",
                component: () => import("../views/account/setup/View"),
            },
        ],
    },
    {
        path: "/spending",
        component: () => import(/* webpackChunkName: "main" */ "../views/spending/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/spending/Overview"),
            },
        ],
    },
    {
        path: "/earnings",
        component: () => import(/* webpackChunkName: "main" */ "../views/earnings/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/earnings/Overview"),
            },
        ],
    },
    {
        path: "/account",
        component: () => import(/* webpackChunkName: "main" */ "../views/account/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/account/personal-information/View.vue"),
            },
            {
                path: "company",
                component: () => import("../views/account/company-information/View.vue"),
            },
            {
                path: "platforms",
                component: () => import("../views/account/platforms/View.vue"),
            },
            {
                path: "markets-niches",
                component: () => import("../views/account/markets-niches/View.vue"),
            },
            {
                path: "notifications",
                component: () => import("../views/account/notifications/View.vue"),
            },
            {
                path: "payments",
                component: () => import("../views/account/payments/View.vue"),
            },
            {
                path: "referrals",
                component: () => import("../views/account/referrals/View.vue"),
            },
        ],
    },
    {
        path: "/daily-tasks",
        component: () => import(/* webpackChunkName: "main" */ "../views/daily-tasks/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/daily-tasks/Overview.vue"),
            },
            {
                path: "review",
                component: () => import("../views/daily-tasks/review/Overview.vue"),
            },
            {
                path: "to-do",
                component: () => import("../views/daily-tasks/to-do/Overview.vue"),
            },
            {
                path: "to-do/:id",
                component: () => import("../views/daily-tasks/to-do/Overview.vue"),
            },
            {
                path: "pending",
                component: () => import("../views/daily-tasks/pending/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("../views/daily-tasks/View.vue"),
            },

        ],
    },
    {
        path: "/leaderboard",
        component: () => import(/* webpackChunkName: "main" */ "../views/leaderboard/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/leaderboard/Overview.vue"),
            },

        ],
    },
    
    {
        path: "/daily-tasks/:id/shared",
        component: () => import(/* webpackChunkName: "main" */ "../views/guest/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/guest/daily-task/View.vue"),
            },
        ],
    },
    {
        path: "/under-maintenance",

        component: () => import("../views/maintenance-mode/__template.vue"),
        children: [
            {
                path: "",
                component: () => import("../views/maintenance-mode/View.vue"),
            },
        ],
    },
    {
        path: "/campaigns",
        component: () => import(/* webpackChunkName: "main" */ "../views/campaigns/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/campaigns/Overview.vue"),
            },
            {
                path: "create",
                component: () => import("../views/campaigns/Create.vue"),
            },
            {
                path: "deliverables",
                component: () => import("../views/campaigns/deliverables/Overview.vue"),
            },
            {
                path: "deliverables/:id",
                component: () => import("../views/campaigns/deliverables/Overview.vue"),
            },
            {
                path: "offers",
                component: () => import("../views/campaigns/offers/Overview.vue"),
            },
            {
                path: "declined",
                component: () => import("../views/campaigns/declined/Overview.vue"),
            },
            {
                path: "pending",
                component: () => import("../views/campaigns/pending/Overview.vue"),
            },
            {
                path: 'ready-to-post',
                component: () =>
                    import('../views/campaigns/pending/Ready-to-post.vue'),
            },
            {
                path: "review",
                component: () => import("../views/campaigns/review/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("../views/campaigns/View.vue"),
            },
        ],
    },
    {
        path: "/admin",
        component: () => import(/* webpackChunkName: "main" */ "../views/admin/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/admin/Dashboard.vue"),
            },
            {
                path: "daily-tasks",
                component: () => import("../views/admin/daily-tasks/Overview.vue"),
            },
            {
                path: "campaigns",
                component: () => import("../views/admin/campaigns/Overview.vue"),
            },
            {
                path: "campaigns/:id",
                component: () => import("../views/admin/campaigns/View.vue"),
            },
            {
                path: "transactions",
                component: () => import("../views/admin/transactions/Overview.vue"),
            },
            {
                path: "users",
                component: () => import("../views/admin/users/Overview.vue"),
            },
            {
                path: "users/:id",
                component: () => import("../views/admin/users/View.vue"),
            },
            {
                path: "system-settings",
                component: () => import("../views/admin/system-settings/Overview.vue"),
            },
            {
                path: "control-panel",
                component: () => import("../views/admin/control-panel/Overview.vue"),
            },
        ],
    },
  
    {
        path: "/brands",
        component: () => import(/* webpackChunkName: "main" */ "../views/brands/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/brands/Overview.vue"),
            },
            {
                path: ":id",
                component: () => import("../views/brands/View.vue"),
            },
        ],
    },
    {
        path: "/influencers",
        component: () => import(/* webpackChunkName: "main" */ "../views/influencers/__template"),
        children: [
            {
                path: ":id",
                component: () => import("../views/influencers/View.vue"),
            },
        ],
    },
    {
        path: "/notifications",
        component: () => import(/* webpackChunkName: "main" */ "../views/notifications/__template"),
        children: [
            {
                path: "",
                component: () => import("../views/notifications/Overview"),
            },
        ],
    },

    {
        path: "/",
        component: () => import(/* webpackChunkName: "main" */ "../views/__template"),
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "main" */ "../views/Dashboard"),
            },
            {
                path: "test",
                component: () => import(/* webpackChunkName: "main" */ "../views/test/test.vue"),
            },
            {
                path: "/:catchAll(.*)",
                component: () => import(/* webpackChunkName: "404" */ "../views/404"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // if (cookies.get("chrp_refresh_token")) {
    next();
    // }else{
    // 	if(to.fullPath.startsWith('/daily-tasks/')){
    // 		next(`${to.fullPath}/shared`);
    // 	}
    // }
});
export default router;
